import React from 'react';

//styles
import LogoContainer from './styles.js';

export default function Logos() {

  const cliente = !!sessionStorage.getItem('@userTheme') ? JSON.parse(sessionStorage.getItem('@userTheme')) : undefined;

  return (
    <LogoContainer>
      <div>
          <img src={`https://storage.googleapis.com/infos-${process.env.REACT_APP_PROJECT_ID}/alestlogo.svg`} alt='alest logo' />
      </div>
      {
        !!cliente
        ? <div><img src={cliente.logo} alt='cliente logo' /></div>
        : null
      }
      <div>
          <img src={`https://storage.googleapis.com/infos-${process.env.REACT_APP_PROJECT_ID}/workplace.png`} alt='workplace logo' />
      </div>
    </LogoContainer>
  );
}
