import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import ReactGA from "react-ga4";

//contexts
import AuthProvider from './contexts/Auth/AuthProvider';
import TokenProvider from './contexts/Token/TokenProvider';
import ContactProvider from './contexts/Contact/ContactProvider';
import { googleAnalytics } from './service/firebase';
import { logEvent } from 'firebase/analytics';

ReactDOM.render(
  <AuthProvider>
    <TokenProvider>
      <ContactProvider>
        <Routes />
      </ContactProvider>
    </TokenProvider>
  </AuthProvider>,
  document.getElementById('root')
);
