import React, { useState, useEffect } from 'react';
import api from '../../../service/api';

//styles
import TermTextContainer from './styles';

export default function Term() {

    document.title = "Termo do Cliente"

    const [ term, setTerm ] = useState('');

    useEffect(() => {
        api.get('/user/term').then(res => {
            setTerm(res.data.termUrl);
        });
    }, []);

    return (
        <TermTextContainer>
            <object data={term} type="application/pdf">
                <embed src={term} type="application/pdf" />
            </object>
        </TermTextContainer>
    );
}
