import React from "react";
import { Link } from "react-router-dom";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PeopleIcon from "@material-ui/icons/People";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
// import BugReportIcon from '@material-ui/icons/BugReport';
import ListIcon from "@material-ui/icons/List";
// import AirplayIcon from '@material-ui/icons/Airplay';
import SettingsIcon from "@material-ui/icons/Settings";
import HelpIcon from "@material-ui/icons/Help";

import AsideContainer from "./styles";

export default function Aside() {
  return (
    <AsideContainer>
      <ul>
        <li className="aside_link">
          <Link to="/admin/panel/">
            <span className="aside_icon">
              <EqualizerIcon fontSize="inherit" />
            </span>
            <span className="aside_text">Relatórios</span>
          </Link>
        </li>
        <li className="aside_link">
          <Link to="/admin/panel/users">
            <span className="aside_icon">
              <PeopleIcon fontSize="inherit" />
            </span>
            <span className="aside_text">Usuários adicionados</span>
          </Link>
        </li>
        <li className="aside_link">
          <Link to="/admin/panel/create/single">
            <span className="aside_icon">
              <PersonAddIcon fontSize="inherit" />
            </span>
            <span className="aside_text">Adicionar usuário único</span>
          </Link>
        </li>
        <li className="aside_link">
          <Link to="/admin/panel/create/batch">
            <span className="aside_icon">
              <GroupAddIcon fontSize="inherit" />
            </span>
            <span className="aside_text">Adicionar vários usuários</span>
          </Link>
        </li>
    {/*<li className="aside_link">
          <Link to="/admin/panel/log">
            <span className="aside_icon">
              <ListIcon fontSize="inherit" />
            </span>
            <span className="aside_text">Registros</span>
          </Link>
        </li>*/}
        {/* <li className='aside_link'>
                    <Link to='/admin/panel/'>
                        <span className='aside_icon'>
                            <AirplayIcon fontSize='inherit'/>
                        </span>
                        <span className='aside_text'>
                            Integrações
                        </span>
                    </Link>
                </li> */}
        {/* <li className='aside_link'>
                    <Link to='/admin/panel/bug'>
                        <span className='aside_icon'>
                            <BugReportIcon fontSize='inherit'/>
                        </span>
                        <span className='aside_text'>
                            Reportar um problema
                        </span>
                    </Link>
                </li> */}
        <li className="aside_link">
          <a
            href="https://docs.google.com/document/d/11ATduRKPnBqTDwNj_7otsxaF3NqkhRyz5TTx_kiLEbM/edit#"
            target="_blank" rel="noopener noreferrer"
          >
            <span className="aside_icon">
              <HelpIcon fontSize="inherit" />
            </span>
            <span className="aside_text">Consultar Erros</span>
          </a>
        </li>

        <li className="aside_link">
          <Link to="/admin/panel/settings">
            <span className="aside_icon">
              <SettingsIcon fontSize="inherit" />
            </span>
            <span className="aside_text">Configurações</span>
          </Link>
        </li>
      </ul>
    </AsideContainer>
  );
}
