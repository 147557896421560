import React, { useContext, useState } from "react";
import { Form } from "@unform/web";

//components
import Card from "../../../components/Card";
import BatchTable from "../../../components/BatchTable";
import FileInput from "../../../components/FileInput";
import AttachmentIcon from "@material-ui/icons/Attachment";
import SendInput from "../../../components/SendInput";
import Check from "../../../components/Check";

//services
import Api from "../../../service/api";

//contexts
import AlertContext from "../../../contexts/Alert/AlertContext";

//styles
import BatchUserContainer from "./styles";

//tools
import { response_translate } from "../../../Tools/responseHandler";
import DataLoader from "../../../components/DataLoader";
import { googleAnalytics } from "../../../service/firebase";
import { logEvent } from "firebase/analytics";

export default function BatchUser() {
  document.title = "Claim Admin - Usuários em massa"
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);

  const LeftThead = () => {
    return (
      <div className="left thead">
        <div className="tr">
          <div className="th">Método</div>
          <div className="th">Enviar e-mail de convite?</div>
          <div className="th">Anexar CSV (máx. 2MB)</div>
          <div className="th">Enviar</div>
        </div>
      </div>
    );
  };

  const handle_submit = async payload => {
    let data = new FormData();
    const options = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const method = Object.keys(payload).shift();

    const file = new Blob(payload[method].file, { type: "text/csv" });

    // Check if file is greater than 2 Mb
    if (file.size > 2 * 1024 ** 2) {
      setAlert({
        type: "error",
        severity: "error",
        message: "O csv ultrapassa o limite de 2 MB",
        show: true,
      });
    } else {
      data.append("sheet", file);
      data.append("action", method);

      try {
        switch (method) {
          case "creation":
            await Api.post("/admin/csv", data, options).then(response => { }, setLoading(true));
            logEvent(googleAnalytics, "Usuários criados");
            break;
          case "update":
            await Api.put("/admin/csv", data, options).then(response => { }, setLoading(true));
            logEvent(googleAnalytics, "Usuários atualizados");
            break;
          case "suspention":
            options.data = data;
            await Api.delete("/admin/csv", options).then(response => { }, setLoading(true));
            logEvent(googleAnalytics, "Usuários suspensos")
            break;
          case "activation":
            await Api.put("/admin/csv", data, options).then(response => { }, setLoading(true));
            logEvent(googleAnalytics, "Usuários ativados")
            break;
          default:
        }
        setAlert({
          type: "ok",
          severity: "success",
          message: "Ação efetuada com sucesso.",
          show: true,
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setAlert({
          type: "error",
          severity: "error",
          message: response_translate(err.response.data.msg),
          show: true,
        });
      }
    }
  };

  const LeftTbody = () => {
    return (
      <div className="left tbody">
        <div className="tr">
          <Form onSubmit={handle_submit}>
            <div className="td">Criação</div>
            <div className="td">
              <Check name="creation.invitation" />
            </div>
            <div className="td" >
              <div title="Carregar CSV" className="buttonConfigs">
                <FileInput name="creation.file" showPreview={true} fileType={".csv"} label={false}  >
                  <AttachmentIcon fontSize="large" />
                </FileInput>
              </div>
            </div>
            <div className="td">
              <div className="buttonConfigs" title="Enviar CSV">
                <SendInput label={false} name="submit_creation" />
              </div>
            </div>
          </Form>
        </div>
        <div className="tr">
          <Form onSubmit={handle_submit}>
            <div className="td">Atualização</div>
            <div className="td"></div>
            <div className="td">
              <div title="Carregar CSV" className="buttonConfigs">

                <FileInput name="update.file" label={false}>
                  <AttachmentIcon fontSize="large" />
                </FileInput>
              </div>

            </div>
            <div className="td">
              <div title="Enviar CSV" className="buttonConfigs">
                <SendInput label={false} name="submit_update" />
              </div>

            </div>
          </Form>
        </div>
        <div className="tr">
          <Form onSubmit={handle_submit}>
            <div className="td">Suspensão</div>
            <div className="td"></div>
            <div className="td">
              <div title="Carregar CSV" className="buttonConfigs">
                <FileInput name="suspention.file" showPreview={true} fileType={".csv"} label={false}>
                  <AttachmentIcon fontSize="large" />
                </FileInput>
              </div>

            </div>
            <div className="td">
              <div title="Enviar CSV" className="buttonConfigs">
                <SendInput label={false} name="submit_suspension" />
              </div>

            </div>
          </Form>
        </div>
        <div className="tr">
          <Form onSubmit={handle_submit}>
            <div className="td">Ativação</div>
            <div className="td"></div>
            <div className="td">
              <div title="Carregar CSV" className="buttonConfigs">
                <FileInput name="activation.file" showPreview={true} fileType={".csv"} label={false}>
                  <AttachmentIcon fontSize="large" />
                </FileInput>
              </div>
            </div>
            <div className="td">
              <div title="Enviar CSV" className="buttonConfigs">
                <SendInput label={false} name="submit_activation" />
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  const RightThead = () => {
    return (
      <div className="right thead">
        <div className="tr">
          <div className="th">Modelo</div>
          <div className="th">Download</div>
        </div>
      </div>
    );
  };

  const RightTbody = () => {
    return (
      <div className="right tbody">
        <div className="tr">
          <div className="td">Criação</div>
          <div className="td">
            <a download href="https://storage.cloud.google.com/claim-portal-alestdemo2-csv-models/creation.csv">
              Download
            </a>
          </div>
        </div>
        <div className="tr">
          <div className="td">Atualização</div>
          <div className="td">
            <a download href="https://storage.cloud.google.com/claim-portal-alestdemo2-csv-models/update.csv">
              Download
            </a>
          </div>
        </div>
        <div className="tr">
          <div className="td">Suspensão</div>
          <div className="td">
            <a download href="https://storage.cloud.google.com/claim-portal-alestdemo2-csv-models/suspension.csv">
              Download
            </a>
          </div>
        </div>
        <div className="tr">
          <div className="td">Ativação</div>
          <div className="td">
            <a download href="https://storage.cloud.google.com/claim-portal-alestdemo2-csv-models/activation.csv">
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <BatchUserContainer>
      {loading ? <DataLoader /> : ""}
      <div className="left_content">
        <h2>Alterações em massa</h2>
        <Card width={"100%"} className="update">
          <BatchTable Thead={LeftThead} Tbody={LeftTbody} />
        </Card>
      </div>
      <div className="right_content">
        <h2>Modelos para download</h2>
        <Card Card width={"100%"} className="models">
          <BatchTable Thead={RightThead} Tbody={RightTbody} />
        </Card>
      </div>
    </BatchUserContainer>
  );
}
