import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import RemoveIcon from "@material-ui/icons/Remove";
import SaveIcon from "@material-ui/icons/Save";
import { Form } from "@unform/web";
import {
  default as React,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
//components
import Input from "../../../components/Input";
//contexts
import AdminGlobalContext from "../../../contexts/AdminGlobalContext/GlobalContext";
import AlertContext from "../../../contexts/Alert/AlertContext";
//services
import Api from "../../../service/api";
import { googleAnalytics } from "../../../service/firebase";
import { logEvent } from "firebase/analytics";
//styles
import UserContainer from "./styles";
import Loading from "../../../components/Loading";
//contexts

export default function EditUser() {
  document.title = "Claim Admin - Editar Usuário"
  const history = useHistory();

  const { editableUser, setEditableUser } = useContext(AdminGlobalContext);
  const { setAlert } = useContext(AlertContext);

  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [newQuestions, setNewQuestions] = useState([]);

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [userId, setUserId] = useState("");
  const [toggle, setToggle] = useState(false);

  // Variáveis de CSS
  const handle_social_name_toggle = toggle ? 'flex-end' : 'flex-start';
  const handle_social_name_toggle_circle = toggle ? '#1976D2' : '#666';

  const [fields, setFields] = useState({
    fields: {
      firstField: "",
      secondField: "",
    },
  });

  useEffect(() => {
    const setFormInitialData = async () => {
      const { id, numDoc } = editableUser;
      Api.get(`/admin/users/${id}`)
        .then(async (res) => {
          const user = res.data.shift();
          setQuestions(user.cpData.auth.questions);
          setAnswers(user.cpData.auth.answers);

          await formRef.current.setData({
            name: user.wpData.core.name,
            id,
            num_doc: numDoc,
            customId: user.cpData.customId,
            email: user.wpData.core.userName,
            division: user.wpData.enterprise.division,
            locality: user.wpData.core.addresses[0].formatted,
            department: user.wpData.enterprise.department,
            phoneNumber: user.wpData.core.phoneNumbers[0].value,
            organization: user.wpData.enterprise.organization,
            title: user.wpData.core.title,
          });
        })
        .catch((err) => {
          setAlert({
            type: "error",
            severity: "error",
            message: `Usuário com ID: ${id} não foi encontrado.`,
            show: true,
          });
        });
    };
    setFormInitialData();
  }, [editableUser, formRef, setAlert]);

  useEffect(() => {
    formRef.current.setData({
      questions: questions,
      answers: answers,
    });
  }, [questions, answers]); //questions and answers
  const handle_submit = async (payload) => {
    try {
      setLoading(true);
      await Api.put("/admin/users", payload);
      logEvent(googleAnalytics, "Um usuário atualizado")
      setAlert({
        type: "ok",
        severity: "success",
        message: "Usuario atualizado com sucesso.",
        show: true,
      });
      setLoading(false);
      history.push("/admin/panel/users");
    } catch (err) {
      setLoading(false);
      setAlert({
        type: "error",
        severity: "error",
        message: "Não foi possível atualizar. " + err.response.data.msg,
        show: true,
      });
    }
  };

  const add_input = () => {
    if (newQuestions.length < 2) {
      setNewQuestions([...newQuestions, 1]);
    }
  };

  const minus_input = () => {
    let newQuestionsList = newQuestions.slice();
    newQuestionsList.shift();
    setNewQuestions(newQuestionsList);
  };

  const handle_clear = () => {
    formRef.current.reset();
    setAlert({
      type: "ok",
      severity: "success",
      message: "Campos restaurados com sucesso.",
      show: true,
    });
  };

  const make_submit = () => {
    formRef.current.submitForm();
    // setQuestions([]);
    // setAnswers([]);
    // setNewQuestions([]);
  };

  useEffect(() => {
    Api.get("/admin/loginFields").then((response) => {
      setFields(response.data.fields);
    });
  }, []);

  return (
    <UserContainer>
      {loading ? <Loading /> : ""}
      <Form onSubmit={handle_submit} ref={formRef}>
        <header>
          <h2>Edição de usuário</h2>
          <div className="add_holder">
            <label htmlFor="clear_button">Limpar</label>
            <IconButton
              id="clear_button"
              size="small"
              color="inherit"
              onClick={handle_clear}
            >
              <ClearIcon fontSize="large" />
            </IconButton>
            <label htmlFor="submit_button">Salvar</label>
            <IconButton
              id="submit_button"
              size="small"
              color="inherit"
              onClick={make_submit}
            >
              <SaveIcon fontSize="large" />
            </IconButton>
          </div>
        </header>
        <div className="content_holder">
          <div className="first_section">
            <h3>Informações gerais</h3>
            <label>Nome:</label>
            <Input name="name" required maxlength={60}/>
            <label>{fields.firstField}</label>
            <Input name="id" disabled required maxlength={40}/>
            <label>{fields.secondField}</label>
            <Input name="num_doc" required maxlength={30}/>
            <label>Custom ID:</label>
            <Input name="customId" maxlength={60}/>
            <label>Cargo:</label>
            <Input name="title" maxlength={60}/>
            <label>E-mail:</label>
            <Input name="email" disabled maxlength={100}/>
            <label>Telefone: </label>
            <Input name="phoneNumber" maxlength={20}/>
            <label>Localidade/Cidade:</label>
            <Input name="locality" maxlength={60}/>
            <label>Departamento:</label>
            <Input name="department" maxlength={60}/>
            <label>Organização:</label>
            <Input name="organization" maxlength={60}/>
            <label>Divisão:</label>
            <Input name="division" maxlength={60}/>
            <label>Nome Social</label>
            <div className="socialNameToggle" onClick={() => setToggle(!toggle)} style={{ justifyContent: `${handle_social_name_toggle}` }}>
              <div name="socialName" className="socialNameToggleCircle" style={{ backgroundColor: `${handle_social_name_toggle_circle}` }} />
              <Input name="socialName" value={toggle} style={{ display: "none" }} />
            </div>
          </div>
          <div className="second_section">
            <h3>Informações de autenticação</h3>
            <label>
              <p>Perguntas</p>
              <div>
                <IconButton size="small" color="inherit" onClick={minus_input}>
                  <RemoveIcon />
                </IconButton>
                <IconButton size="small" color="inherit" onClick={add_input}>
                  <AddIcon />
                </IconButton>
              </div>
            </label>
            {questions.map((item, index) => (
              <Input key={`${index}_${item}`} name={`questions.${index}`} maxlength={50}/>
            ))}
            {newQuestions.map((item, index) => (
              <Input
                key={`${index}_${item}`}
                name={`questions.${index + questions.length}`}
                maxlength={50}
              />
            ))}
            <label>
              <p>Respostas</p>
              <div>
                <IconButton size="small" color="inherit" onClick={minus_input}>
                  <RemoveIcon />
                </IconButton>
                <IconButton size="small" color="inherit" onClick={add_input}>
                  <AddIcon />
                </IconButton>
              </div>
            </label>
            {answers.map((item, index) => (
              <Input key={`${index}_${item}`} name={`answers.${index}`} maxlength={50}/>
            ))}
            {newQuestions.map((item, index) => (
              <Input
                key={`${index}_${item}`}
                name={`answers.${index + answers.length}`}
                maxlength={50}
              />
            ))}
          </div>
        </div>
      </Form>
    </UserContainer >
  );
}