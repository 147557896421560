import React from 'react';

//styles
import TermTextContainer from './styles';

export default function Term() {
    document.title = "Claim Portal - Termo Workplace"
    return (
        <TermTextContainer>
            <h1>Termos e Condicões Gerais</h1><br />
            <h2>Política de Uso Aceitável do Workplace</h2><br />
            <p>
                Bem-vindo ao Workplace do Facebook! Este é um serviço diferente da versão comum do Facebook e é destinado a organizações. Assim, se você estiver usando nosso serviço em um local de trabalho ou em um dispositivo ou conta atribuída a você por seu empregador ou outra organização (sua “Organização”), essa Organização provavelmente terá as próprias políticas para o uso do Workplace, e o uso que você faz do serviço deverá estar de acordo com essas políticas. Entre em contato com sua Organização se tiver dúvidas sobre as políticas aplicadas por ela. <br/>
                Para obter mais informações sobre as práticas de privacidade do Workplace, consulte a Política de Privacidade do Workplace. Como usar o Workplace de forma responsável Além das políticas de sua Organização, também criamos as regras a seguir para ajudar a garantir o uso responsável de nossos serviços. Para acessar a API do Workplace, é necessário cumprir nossa Política da Plataforma do Workplace. <br/>
                Ao usar o Workplace, você não deve: Ocultar sua identidade, fingir ser outra pessoa ou mentir sobre sua afiliação a qualquer pessoa ou entidade. Envolver-se em atividade que explore, fira ou ameace ferir crianças. Criar ou exibir conteúdo ilegal, discriminatório, prejudicial, fraudulento, enganoso ou difamatório ou que promova ou incentive violência, violação de leis, automutilação, distúrbios alimentares ou abuso de drogas. Violar as leis ou infringir os direitos do Facebook ou de qualquer terceiro. Interferir no funcionamento normal do Workplace ou no uso do Workplace por outra pessoa. Acessar o Workplace ou conteúdo ou informações relacionados a ele por meios não autorizados pelo Facebook (como scraping ou crawling), burlar os controles de acesso ou tentar obter acesso não autorizado ao Workplace ou a sistemas, senhas ou contas relacionadas a ele. Compartilhar tokens de acesso do administrador ou conceder permissões de acesso do aplicativo semelhantes para qualquer terceiro não expressamente aprovado pelo Facebook. Quando você decidir conceder esse acesso de administrador para um terceiro aprovado via token ou permissão de aplicativo, permita que o terceiro acesse seus dados ou conteúdo apenas na medida do necessário para o objetivo aprovado pelo Facebook e de acordo com suas instruções.<br/> 
                Reservamo-nos o direito de limitar o acesso desse terceiro (por exemplo, redefinindo o token de acesso ou removendo a permissão do aplicativo) a qualquer momento se acreditarmos que tal acesso foi ou será usado indevidamente. Carregar de vírus, malware ou outro código mal-intencionado ou fazer algo que possa danificar, inutilizar, sobrecarregar ou prejudicar o Workplace ou sistemas relacionados a ele (como um ataque de negação de serviço ou interferência na renderização de página ou outro recurso do Workplace). Observe também que o Workplace não é voltado para crianças com menos de 13 anos de idade. Portanto, se você tiver menos de 13 anos de idade, não poderá acessar ou usar o serviço. Nossas responsabilidades e seu feedback O Facebook pode usar tecnologia automatizada para detectar pornografia infantil ou outros materiais ilegais ou abusivos, ou quaisquer materiais que considerarmos, de maneira justificada, que podem prejudicar o Workplace, os usuários ou terceiros. Podemos, mas não temos a obrigação de remover ou limitar o acesso a seus dados ou conteúdo se acreditarmos que eles violam essa Política de Uso Aceitável, o Acordo Empresarial do Workplace de sua Organização ou as leis e regulamentos aplicáveis. O Facebook fornecerá para você ou sua Organização aviso prévio dessa ação, a não ser que seja proibido por lei.<br/> 
                Agradecemos todo o feedback sobre o Workplace, mas observe que podemos usar seus comentários e sugestões sem qualquer obrigação ou compensação para você. Data de entrada em vigor: 25 de maio de 2018 
            </p>
        </TermTextContainer>
          
    );
}
