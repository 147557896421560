import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import Input from '../../../components/Input';
import Api from '../../../service/api';
import { googleAnalytics } from '../../../service/firebase';
import { logEvent } from 'firebase/analytics';

//resources
import Button from '../../../components/Button';

//styles
import LoginContainer from './styles.js';

//contexts
import AuthContext from '../../../contexts/Auth/AuthContext';
import { ThemeContext } from 'styled-components';
import AlertContext from '../../../contexts/Alert/AlertContext';
import UserGlobalContext from '../../../contexts/UserGlobalContext/UserContext';
import { useEffect } from 'react';
import { useState } from 'react';

export default function Login({ computedMatch }) {
    document.title = "Claim Portal - Login"
    const { action } = computedMatch.params;
    const history = useHistory();

    const [fields, setFields] = useState({
        fields: {
            firstField: '',
            secondField: '',
        }
    })

    const { label, placeholder } = useContext(ThemeContext);
    const { setUserAuth, setUserInformation } = useContext(AuthContext);
    const { setAlert } = useContext(AlertContext);
    const { setLoading } = useContext(UserGlobalContext);
    document.title = "Claim Portal - Login"

    useEffect(() => {
        Api.get('/admin/loginFields').then((response) => {
            setFields(response.data.fields)
        })
    }, [])

    const handle_submit = ({ id, numDoc }) => {
        setLoading(true);
        const normalizedId = id.trim();
        const normalizedNumDoc = numDoc.trim();
        if (normalizedId === "" || normalizedNumDoc === "") {
            setAlert({
                show: true,
                message: 'Por favor, não deixe nenhum campo vazio.',
                type: 'not ok',
                severity: 'warning'
            })
            setLoading(false)
        } else {
            Api.post('user/verify/auth', { id, numDoc }).then(res => {
                if (res.status === 200) {
                    setLoading(false);
                    setUserAuth(true);
                    setUserInformation({ id, numDoc })
                    history.push({ pathname: `/questions/${action}`, state: { method: action } });
                }
            }).catch(err => {
                setLoading(false);
                setAlert({
                    type: 'not ok',
                    severity: 'error',
                    message: err.response.data.msg,
                    show: true
                });
            });
        }
    }

    return (
        <LoginContainer>
            <Form onSubmit={handle_submit}>
                <h2>Login</h2>
                <label>{fields.firstField}</label>
                <Input name='id' />
                <label>{fields.secondField}</label>
                <Input name='numDoc' />
                <Button>
                    Entrar!
                </Button>
            </Form>
        </LoginContainer>
    );
}
